import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import Container from "../components/container"
//import Image from "../components/image"
import SEO from "../components/seo"

const Covid = () => (
  <Layout>
    <SEO title="Covid19" />
    <Container>
      <h1>Party Safety and Covid-19</h1>
      <p>
      The time to party has finally come and are so extatic to be able to celebrate
      this major life-milestone with everyone! Though many restrictions have been
      lifted in the State of Michigan, we are still putting many precautions in
      place to be mindful of everyone's health and saftey during the event.
      </p>
      <h3>The Big Ask</h3>
      <p>
      If you have Covid-19, have cared for someone with Covid-19
      or been in direct contact with someone who has been diagnosed with Covid-19
      in the two weeks prior to July 25th, we ask that you refrain from
      attending the event for the saftey of others.
      </p>
      <h3>What we're doing</h3>
      <ul>
        <li>Food (both dinner and hors d'oeuvres) are being pre-plated/packaged
        and served individually.</li>
        <li>Beverages are either bottled or will be served by a designated
        individual (and marked with sharpie).</li>
        <li>Along with access to restrooms for hand-washing, hand-sanitizer
        will be placed strategically around the party.</li>
        <li>The party is being hosted outdoors to provide ample air-flow and
        room for people to mix and migle at a safe distance.</li>
        <li>A large tent will be setup to keep us all out of the heat!</li>
      </ul>
      <h3>What we ask you to do</h3>
      <ul>
        <li>Bring your mask! If you go indoors (to use the restroom or cool off)
        we ask that you wear a mask.</li>
        <li>Wash your hands frequently and thoroughly.</li>
        <li>Avoid contact with others. Though this can be the most difficult part
        of fighiting a pandemic (especially while celebrating a marriage),
        we are sticking to "virtual hugs"!</li>
      </ul>
      <h3>More Info</h3>
      <p>
      For more info on Covid-19, see the <a href="https://www.cdc.gov/coronavirus/2019-ncov/index.html">CDC</a> and <a href="https://www.michigan.gov/coronavirus/">State of Michigan</a> pages.
      </p>
    </Container>
  </Layout>
)

export default Covid
